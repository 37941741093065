




















import PointOfServiceUtils, {
  PointOfService,
} from '@/utils/PointOfServiceUtils';
import PointOfServiceDescriptionForm from '@/components/pointofservice/PointOfServiceDescriptionForm.vue';
import Vue from 'vue';

export default Vue.extend({
  components: {
    PointOfServiceDescriptionForm,
  },

  data(): {
    state: {
      valid: boolean;
      submit: boolean;
      delete: boolean;
      errors?: any[];
    };
    item?: Partial<PointOfService>;
  } {
    return {
      state: {
        valid: false,
        submit: false,
        delete: false,
        errors: undefined,
      },
      item: undefined,
    };
  },

  async activated() {
    try {
      this.item = undefined;
      const result = await PointOfServiceUtils.api.get(
        parseInt(this.$route.params?.id)
      );
      this.item = PointOfServiceUtils.pointOfService.generateDefault(result);
      (this.$refs.form as any).resetValidation();
    } catch (error: any) {
      console.error(error);
      if (error.response.status === 404) {
        // TODO: not found
      }
    }
  },

  methods: {
    async handleSubmit() {
      if (this.item) {
        try {
          this.state.errors = undefined;
          this.state.submit = true;
          (this.$refs.form as any).validate();

          this.item = PointOfServiceUtils.pointOfService.generateDefault(
            await PointOfServiceUtils.api.update(this.item)
          );

          await PointOfServiceUtils.api.generateSearchDataset(this.item?.id);

          (this.$refs.form as any).resetValidation();
          this.$toast.push({
            text: this.$t('success.edit', [this.$tc('pointofservice.label')]),
            type: 'success',
          });
        } catch (error: any) {
          this.$toast.push({
            text: this.$t('error.edit', [this.$tc('pointofservice.label')]),
            type: 'error',
          });
          this.state.errors = error; // TODO: handle errors
        } finally {
          this.state.submit = false;
        }
      }
    },
  },
});
